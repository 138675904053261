import React, { FC } from "react";
import { LazyImage } from "../LazyImage";

interface IconAndTitleWithContentProps {
  iconURL: string;
  alt: string;
  title: string;
  content: string;
}

export const IconAndTitleWithContent: FC<IconAndTitleWithContentProps> = ({
  iconURL,
  alt,
  content,
  title,
}) => {
  return (
    <article className="box-modern box-modern-sm box-modern_alternate article-item-box">
      <div className="box-modern-media">
        <div className={`box-modern-icon `}>
          <LazyImage alt={alt} height={64} width={64} src={iconURL} />
        </div>
      </div>
      <p className="box-modern-title">{title}</p>
      <div className="box-modern-text">
        <p>{content}</p>
      </div>
    </article>
  );
};
